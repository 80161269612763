import React from 'react'
import Logo from './../../src/Assets/cropped-logosocial-300x300-min.png'
import LogoCatalogo from './../Assets/catalogos-min.png'
import { testMode, appVersion } from '../api_vars'

export default function Header({ showCatalogue }) {
    return (
        <div>
            <header className="Form-header1">
                <p>COTIZA TU PEDIDO {
                    testMode ? `- ${appVersion}` : ""
                }</p>
            </header>
            <header className="Form-header">
                <img src={Logo} alt="Imagen no disponible" />

                {
                    showCatalogue ?
                        <div className="Form-header-section2">
                            {/* <p>Catálogos</p> */}
                            <img src={LogoCatalogo} alt="Imagen no disponible" />
                        </div>
                        :
                        null
                }
            </header>
        </div>
    )
}
