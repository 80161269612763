const host = "https://test.santascarnitas.com/"
const dir_api_name = "API_Test"
const testMode = true
const appVersion = "beta 1.3"

// const host = "https://precompra.santascarnitas.com/"
// const dir_api_name = "API"
// const testMode = false
// const appVersion = "1.2.7.1"


// const host = "http://localhost/"
// const dir_api_name = "API"


export { host, dir_api_name, testMode, appVersion }