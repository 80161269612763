import React, { useState, useEffect } from 'react'
import { TreeSelect } from 'antd';


import 'antd/lib/tree-select/style/css'
import { host } from '../api_vars';


const { TreeNode } = TreeSelect;

export default function Address({ setUserData, setCart }) {

    const [address, setAddress] = useState(undefined)
    const [cities, setCities] = useState([])
    const [neighBorHoods, setNeighBorHoods] = useState({})
    const [commune, setCommune] = useState({})
    const [loading, setLoading] = useState(true)

    const onChange = value => {
        console.log(value);
        if (value === undefined) {
            setAddress(value)
            setUserData(prevState => { return { ...prevState, idDirection: "", city: "", neighborhood: "", commune: "" } })
        }
    };

    const consulCities = () => {
        const url = host + "API_Test/api_consult_address.php"
        const query = `?type=cities`

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err)).then(citiesRes => {
            setCities(citiesRes)

            citiesRes.map((citiesItem, key) =>

                consultNeighborhoods(citiesItem.MUNICIPIO).then(

                    neighborhoodRes => {

                        setNeighBorHoods(prevState => { return { ...prevState, [`${citiesItem.MUNICIPIO}`]: neighborhoodRes } })

                        neighborhoodRes.map((commune, key2) =>

                            consultCommune(commune.Barrios, citiesItem.MUNICIPIO).then(
                                communeRes => {
                                    setCommune(prevState => { return { ...prevState, [`${citiesItem.MUNICIPIO}-${commune.Barrios}`]: communeRes } })
                                    if (neighborhoodRes.length === (key2 + 1) && citiesRes.length === (key + 1)) {
                                        setLoading(false)
                                    }
                                }
                            )

                        )
                    }
                )
            )
        })

    }

    const consultNeighborhoods = (city) => {
        const url = host + "API_Test/api_consult_address.php"
        const query = `?type=neighborhoods&city=${city}`

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }

    const consultCommune = (neighborhood, city) => {
        const url = host + "API_Test/api_consult_address.php"
        const query = `?type=commune&neighborhood=${neighborhood}&city=${city}`

        let headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Origin', host);

        let miInit = {
            method: 'GET',
            headers: headers,
            mode: 'cors',
        };


        return fetch(url + query, miInit).then((response) => {
            if (response.ok) {
                return response.json();
            }

            else { throw response };
        }).catch((err) => console.error(err))
    }

    useEffect(() => {

        consulCities()

    }, [])

    const setAddres = (id, orientation, city, neighborhood, commune, availability, price, key, key2, key3) => {

        if (availability === "false") {

            alert("Lo sentimos. Esta ubicación no se encuentra disponible en este momento.")

        } else {
            setAddress(`${city} ${neighborhood} ${commune} ${key}-${key2}-${key3}`)
            setUserData(prevState => {
                return {
                    ...prevState,
                    idDirection: id,
                    orientation: orientation,
                    city: encodeURI(escape(city)),
                    neighborhood: encodeURI(escape(neighborhood)),
                    commune: encodeURI(escape(commune)),
                }
            })
            setCart(prevState => { return {
                ...prevState,
                delivery_price: price
            } })
        }

    }


    return (
        <TreeSelect
            showSearch
            style={{ width: '100%' }}
            value={address}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Buscar ubicación:"
            allowClear
            onChange={onChange}
            id="userData-location"
        >
            {
                loading ?
                    <TreeNode value="Default" title="Cargando..." selectable={false}></TreeNode>
                    :
                    cities.map(
                        (item, key) =>
                            <TreeNode value={`${item.MUNICIPIO} ${key}`} title={item.MUNICIPIO} onClick={(e) => console.log(e)} selectable={false}>
                                {
                                    neighBorHoods[`${item.MUNICIPIO}`] ? neighBorHoods[`${item.MUNICIPIO}`].map(

                                        (item2, key2) => <TreeNode value={`${item2.Barrios}  ${key}-${key2}`} title={item2.Barrios} selectable={false}>

                                            {
                                                commune[`${item.MUNICIPIO}-${item2.Barrios}`] ? commune[`${item.MUNICIPIO}-${item2.Barrios}`].map(

                                                    (item3, key3) =>
                                                        <TreeNode value={`${item.MUNICIPIO} ${item2.Barrios} ${item3.COMUNA} ${key}-${key2}-${key3}`}
                                                            title=
                                                            {
                                                                <p style={{ margin: 0 }} onClick={() => setAddres(item3.ID, item3.SECTOR, item.MUNICIPIO, item2.Barrios, item3.COMUNA, item3.disponibilidad, item3.tarifa_domicilio, key, key2, key3)}> {item.MUNICIPIO} / {item2.Barrios} / {item3.COMUNA}</p>
                                                            }
                                                        />
                                                )
                                                    : null
                                            }

                                        </TreeNode>
                                    ) : null
                                }
                            </TreeNode>
                    )
            }
        </TreeSelect>
    );

}