import React from 'react'

export default function CartSummary({Cart, combos, total, title}) {
    return (
        <footer className="Form-footer">
            <section className={"Form-footer-cart " + title === "Resumen" ? "Form-footer-summary" : ""}>
                <h2>{title}</h2>
                <div className="Form-footer-cart-body">
                    <ul>
                        {
                            Cart.pig.length === 0 && Cart.beef.length === 0 && Cart.chicken.length === 0 && Cart.fish.length === 0 && Cart.sausages.length === 0 && Cart.cravings.length === 0
                                && Cart.combos.length === 0
                                ?
                                <li style={{ color: "#707070" }}>Vacío</li> : null
                        }
                        {
                            Cart.pig.map(item => {
                                return <li key={item.id}>{item.name} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} - <i>{item.TypeOfCut}</i></li>
                            })
                        }
                        {
                            Cart.beef.length === 0 ? null :
                                Cart.beef.map(item => {
                                    return <li key={item.id}>{item.name} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} - {item.TypeOfCut}</li>
                                })
                        }
                        {
                            Cart.chicken.length === 0 ? null :
                                Cart.chicken.map(item => {
                                    return <li key={item.id}>{item.name} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} - {item.TypeOfCut}</li>
                                })
                        }
                        {
                            Cart.fish.length === 0 ? null :
                                Cart.fish.map(item => {
                                    return <li key={item.id}>{item.name} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} - {item.TypeOfCut}</li>
                                })
                        }
                        {
                            Cart.sausages.length === 0 ? null :
                                Cart.sausages.map(item => {
                                    return <li key={item.id}>{item.name} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} {" - " + item.TypeOfCut}</li>
                                })
                        }
                        {
                            Cart.cravings.length === 0 ? null :
                                Cart.cravings.map(item => {
                                    return <li key={item.id}>{item.name} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} {" " + item.TypeOfCut}</li>
                                })
                        }
                        {
                            Cart.combos.length === 0 ? null :
                                Cart.combos.map((item, index) => {
                                    return <li key={combos[item.index].id}>
                                        {combos[item.index].title} x {item.quantity} | <b>{Number(combos[item.index].price * (item.quantity === "" || item.quantity === null ? 1 : item.quantity)).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>
                                        <ul>
                                            {combos[item.index].content.map((item, index) => {
                                                return <li>{item.description} x {item.quantity} {item.unit} - Total : {Number(item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} {" " + item.TypeOfCut}</li>
                                            })}
                                        </ul>
                                    </li>
                                })
                        }
                    </ul>
                    <p style={{fontSize: "13px"}}>Valor domicilio: <b>{Number(Cart.delivery_price).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
                    <p style={{fontSize: "16px"}}>Total: <b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
                    <small className="Form-footer-cart-body-info">Los valores de esta cotización son aproximados, pueden variar por su peso exacto al momento de la liquidación.</small>
                </div>
            </section>
        </footer>

    )
}
