const combosPropsVer2 = [
    {
        title: "COMBO 1 | ASADO CLÁSICO",
        collapseStatus: 'collapsed',
        price: 48600,
        id: "cm1",
        ver: 2,
        content: [
            {
                id: "1",
                description: "CARNE DE CERDO",
                lines: "CERDO",
                quantity: "3",
                unit: "LIBRAS",
                price: "12600",
                finalPrice: "",
                weight: 1500, TypeOfCut: ""
            },
            {
                id: "2",
                description: "COSTILLA",
                lines: "res",
                quantity: "2",
                unit: "LIBRAS",
                price: "12000",
                finalPrice: "",
                weight: 1000, TypeOfCut: ""
            },
            {
                id: "3",
                description: "CHURRASCO",
                lines: "res",
                quantity: "1",
                unit: "LIBRA",
                price: "12000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            },
            {
                id: "4",
                description: "CHORIZO DE CERDO",
                lines: "CERDO",
                quantity: "4",
                unit: "UNIDADES",
                price: "4000",
                finalPrice: "",
                weight: 0, TypeOfCut: ""
            },
            {
                id: "5",
                description: "FILETE DE PECHUGA (5 und)",
                lines: "res",
                quantity: "1",
                unit: "LIBRA",
                price: "8000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }
        ]
    },
    {
        title: "COMBO 2 | PAISA...",
        collapseStatus: 'collapsed',
        price: 45000,
        id: "cm2",
        ver: 2,
        content: [
            {
                id: "1",
                description: "TOCINO",
                lines: "CERDO",
                quantity: "3",
                unit: "LIBRAS",
                price: "16500",
                finalPrice: "",
                weight: 1500, TypeOfCut: ""
            },
            {
                id: "2",
                description: "PEZUÑA DE FRIJOL",
                lines: "RES",
                quantity: "1",
                unit: "LIBRA",
                price: "3000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }, {
                id: "3",
                description: "PARA MOLER",
                lines: "RES",
                quantity: "2",
                unit: "LIBRAS",
                price: "13500",
                finalPrice: "",
                weight: 1000, TypeOfCut: ""
            }, {
                id: "4",
                description: "CHORIZO DE CERDO",
                lines: "CERDO",
                quantity: "4",
                unit: "UNIDADES",
                price: "4000",
                finalPrice: "",
                weight: 0, TypeOfCut: ""
            }, {
                id: "5",
                description: "FILETE DE PECHUGA (5 und)",
                lines: "POLLO",
                quantity: "1",
                unit: "LIBRA",
                price: "8000",
                weight: 500, TypeOfCut: ""
            }
        ]
    },
    {
        title: "COMBO 3 | SANCOCHO",
        collapseStatus: 'collapsed',
        price: 38000,
        id: "cm3",
        ver: 2,
        content: [
            {
                id: "1",
                description: "CARNE PARA SANCOCHO (MORRILLO - PECHO)",
                lines: "RES",
                quantity: "3",
                unit: "LIBRAS",
                price: "20000",
                finalPrice: "",
                weight: 1500, TypeOfCut: ""
            },
            {
                id: "2",
                description: "ESPINAZO",
                lines: "RES",
                quantity: "2",
                unit: "LIBRAS",
                price: "6000",
                finalPrice: "",
                weight: 1000, TypeOfCut: ""
            },
            {
                id: "3",
                description: "PEZUÑA CARNUDA",
                lines: "RES",
                quantity: "2",
                unit: "LIBRAS",
                price: "7000",
                finalPrice: "",
                weight: 1000, TypeOfCut: ""
            },
            {
                id: "4",
                description: "DE MUSLO",
                lines: "RES",
                quantity: "2",
                unit: "LIBRAS",
                price: "5000",
                finalPrice: "",
                weight: 1000, TypeOfCut: ""
            }
        ]
    },
    {
        title: "COMBO 4 | ASADO",
        collapseStatus: 'collapsed',
        price: 48000,
        id: "cm4",
        ver: 2,
        content: [
            {
                id: "1",
                description: "CAÑÓN",
                lines: "RES",
                quantity: "1",
                unit: "LIBRA",
                price: "6000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            },
            {
                id: "2",
                description: "PUNTA ANCA / CHATA",
                lines: "RES",
                quantity: "1",
                unit: "LIBRA",
                price: "12000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }, {
                id: "3",
                description: "TOCINO",
                lines: "CERDO",
                quantity: "1",
                unit: "LIBRA",
                price: "6000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }, {
                id: "4",
                description: "COSTILLA DE CERDO",
                lines: "CERDO",
                quantity: "1",
                unit: "LIBRA",
                price: "6000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }, {
                id: "5",
                description: "CHORIZO DE CERDO",
                lines: "CERDO",
                quantity: "4",
                unit: "UNIDADES",
                price: "4000",
                finalPrice: "",
                weight: 0, TypeOfCut: ""
            }, {
                id: "6",
                description: "PICADA ANTIOQUEÑA",
                lines: "CERDO",
                quantity: "1",
                unit: "LIBRA",
                price: "6000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }, {
                id: "7",
                description: "FILETE DE PECHUGA",
                lines: "POLLO",
                quantity: "1",
                unit: "LIBRA",
                price: "8000",
                finalPrice: "",
                weight: 500, TypeOfCut: ""
            }
        ]
    },
]

export { combosPropsVer2 }