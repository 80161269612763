import React from 'react';
import { BrowserRouter as Router, Route, Switch, BrowserRouter } from 'react-router-dom';

import App from '../Components/App';
import API_Cart from '../Components/API_Cart';


const Routes = () => {

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/">
                    <App />
                </Route>
                <Route path="/Registro/:id" component={API_Cart}></Route>
            </Switch>
        </BrowserRouter>
    )

}


export default Routes