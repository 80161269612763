const { default: styled } = require("styled-components");

const FooterContainer = styled.div`
    background-color: #b9180d;
    height: 25vh;
    bottom: 0px;
    margin-top: 10vh;
    padding: 0px 40px;
    justify-content: start;
    z-index: 1000;
    /* align-items:flex-start; */
    img{
        width:150px;
    }
    .Footer-section{
        margin-left:50px;
        padding-top: 30px;
        color: white;
        h3{
            color:white;
        }
        li{
            list-style: none;
        }
    }
    .LogoFooter-container{
        height: 25vh;
    }
    @media (max-width: 600px){
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        justify-content: start;
        bottom: 0px;
        padding-bottom: 80px;
        .Footer-section{
            margin-left:0px;
            padding-top: 10px;
        }
        .LogoFooter-container{
            align-items: center;
            justify-content: center;
            width: 100%;
        }
    }
`


export { FooterContainer }