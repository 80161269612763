import React from 'react'
import { FooterContainer } from './Footer/Styles/Styles'
import Logo from './../../src/Assets/cropped-logosocial-300x300-min.png'

export default function Footer() {
    return (
        <FooterContainer className="flex">

            <div className="LogoFooter-container flex">

                <img src={Logo} alt="Imagen no disponible" />

            </div>

            <div className="Footer-section">
                <h3>Direcciones</h3>
                <ul>
                    <li>Direccion 1</li>
                    <li>Direccion 2</li>
                </ul>
            </div>

            <div className="Footer-section">

                <h3>Contacto</h3>

                <ul>
                    <li>Tel: <a href="tel:5742041077">+5742041077</a></li>
                    <li>Email: <a href="email:contacto@santascarnitas.com">contacto@santascarnitas.com</a></li>
                </ul>

            </div>


        </FooterContainer>
    )
}
