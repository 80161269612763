import React, { useState, useEffect } from 'react';
import moment from 'moment'

///////////////////////// Assets

import LogoDown from './../../src/Assets/LogoDown.svg'
// import 'antd/dist/antd.compact.min.css';
import 'antd/lib/notification/style/css'
import 'antd/lib/date-picker/style/css'
import 'antd/lib/modal/style/css'
import './../dist/css/main.min.css'
import locale from 'antd/es/date-picker/locale/es_ES'

///////////////////////// Assets




///////////////////////// Component's loaded

import Header from './header'; // Header of site
// import PrintCategories from './printSections'; // Print the categories by tipe of product (beef, chicken, fish and more)
import PrintCombos from './printCombos'; // Print the combos category

///////////////////////// Component's loaded




//////////////////////// Variables


import { host, dir_api_name } from '../api_vars'
import { notification, Modal, DatePicker } from 'antd';
import CartSummary from './Home/Cart';
import { combosPropsVer2 } from './Props/combos';
import Footer from './Footer';
import Address from './Address';


//////////////////////// Variables



///////////////////////// Vars, states, consts, params propeties and more
/**
 * @var {json} sectionsCollapseStatus - Collapse status of categories | Estado de desplegable de cada categoría
 * @var {number} step - Actually step in shopping  process | Paso actual del proceso de compra
 * @const {string} host - Link of API | Link de la API
 * @prop {array[json]} meatTypeList - List of products this is initiali empty when app load this will to fetch the list with a API | Lista de productos. Esta está inicialmente vacía y cuando la app inicie traerá la lista desde una API
 * @prop {array[json]} combos - List of Combos / Promotions. | Lista de combos / promociones
 * @var { json{ name: array } } Cart - Cart | Carrito de compra
 * @var {json} SubTotals - Sub totals by category | Sub totals por categoría
 * @var {number} total - Total of Cart | Total del carrito de compra
 * @var {json} userData -  Datos del usuario en el formulario - User data in form
 */
///////////////////////// Vars, states, consts, params propeties and more


///////////////////////// Funtions

/**
 * @function changeQuantityItem - Change number of product and add to Cart if is more than 0 with (@function toggleItemCart). Or if is iqual to 0 and is already added in the Cart will be deleted with (@function toggleItemCart)
 * @function toggleItemCart - Add product to cart if is not added. If is already added will to update it or deleted if is igual to 0.
 * @function changeMeetCut - Change the type of meat cut by product
 * @function RegisterUserData - set User data of the form
 * @event {by[Cart]} useEffet - set the Total, set sub totals and Print the cart in the screen when the Cart is update
 * @event {by[]} useEffect - Load list of products when the app is loaded
 * @function register_order - register user order in the database with her user data and Cart content
 * @function collapseAll - collapse all sections
 */

///////////////////////// Funtions

const App = () => {

  /////////////////// START States ///////////////////

  notification.config({
    duration: 4,
    bottom: window.screen.width <= 700 ? 17 : 80
  });

  const [device_data, setDeviceData] = useState()


  const [sectionsCollapseStatus, setSectionsCollapseStatus] = useState({
    pig: 'collapsed',
    beef: 'collapsed',
    chicken: 'collapsed',
    fish: 'collapsed',
    sausages: 'collapsed',
    cravings: 'collapsed',
    combos: "collapsed"
  })

  const [step, setStep] = useState(0) /* Paso actual en el proceso de compra - Actually step in the shopping process. */

  const [meatTypeList, setMeetTypeList] = useState({
    pig: [
    ],
    beef: [
    ],
    chicken: [
    ],
    fish: [],
    sausages: [],
    cravings: []
  }) // Stock de la tienda - Stock of the store

  const [combos, setCombos] = useState(
    combosPropsVer2
  ) // Combos


  const PresentationsList = [
    { "name": "Porcionado 5xLb", "selectable": true },
    { "name": "Porcionado 4xLb", "selectable": true },
    { "name": "Porcionado 3xLb", "selectable": true },
    { "name": "Porcionado 2xLb", "selectable": true },
    { "name": "Entero", "selectable": true },
    { "name": "Molido", "selectable": true },
    { "name": "Mariposa", "selectable": true },
    { "name": "Goulash", "selectable": true },
  ]

  const [Cart, setCart] = useState({
    pig: [],
    beef: [],
    chicken: [],
    fish: [],
    sausages: [],
    cravings: [],
    combos: [],
    delivery_price: 0
  }) //Carrito de compras - Cart

  const [SubTotals, setSubTotals] = useState({
    pig: 0,
    beef: 0,
    chicken: 0,
    fish: 0,
    sausages: 0,
    cravings: 0,
    combos: 0
  }) // Sub totales de cada sección - Sub totals by category

  const [total, setTotal] = useState(0) // Total

  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    id: "",
    email: '',
    cell: "",
    tel: "",
    address: "",
    orientation: "",
    city: "",
    neighborhood: "",
    commune: "",
    idDirection: "",
    point_reference: "",
    date: "",
    hour: "",
    minute: "",
    am_pm: "",
    payment_method: ""
  }) // Datos del usuario en el formulario - User data in form

  const [modal, setModal] = useState({
    visble: false
  })

  const handleOk = e => {
    setModal({
      visible: false,
    });
    setStep(2);
  };

  const handleCancel = e => {
    setModal({
      visible: false,
    });
    setStep(0);
  };

  let errorCount = 0

  /////////////////// END States ///////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  /////////////////// START Functions ///////////////////

  const getMessageNotification = (title, quantity, price) => {
    return title + " x " + quantity + " | " + Number(price * quantity).toLocaleString("de-AT", { style: "currency", currency: "USD" })
  }

  const openNotification = (title, type) => {
    let message
    const notificationSucess = (message) => {
      notification.success({
        message: message,
        description: title,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        placement: "bottomRight",
        className: "notifications"
      });
    }

    const notificationDeleted = (message) => {
      notification.error({
        message: message,
        description: title,
        onClick: () => {
          console.log('Notification Clicked!');
        },
        placement: "bottomRight",
        className: "notifications"
      })
    }



    switch (type) {
      case "product_added":
        message = "Añadido al carrito!"
        notificationSucess(message)
        break;

      case "product_deleted":
        message = "Eliminado!"
        notificationDeleted(message)
        break;

      case "product_updated":
        message = "Actualizado!"
        notificationSucess(message)
        break;

      case "error_reload":
        message = "Error al cargar!"
        notificationDeleted(message)
        break;

      case "error_finish":
        message = "Error al cargar"
        notificationDeleted(message)
        break;

      case "consult_date":

        message = "Verificando disponibilidad"
        notificationSucess(message)
        break;

      case "date_unavailable":

        message = "No disponible"
        notificationDeleted(message)
        break;
      case "date_available":

        message = "Disponible"
        notificationSucess(message)
        break;

      default:
        return false;
    }


  };

  function changeQuantityItem(e, id, meatType, name, price, unit) {

    if (e.target.value > 99) { e.target.value = e.target.value.slice(0, 2); e.preventDefault(); return false };

    let quantity = document.getElementById("Quantity-" + id).value


    if (quantity.indexOf(".") !== -1) {
      alert("No se permiten '.', ni ','")
      quantity = quantity.replace(/[^0-9]/g, "").slice(-1)
      document.getElementById("Quantity-" + id).value = e.target.value.replace(/[^0-9]/g, "").slice(0,1)

    }

    if (quantity < 0) quantity = 0


    let totalInput = document.getElementById("Total-" + id)
    let totalPrice = Number(quantity) * Number(price)

    // totalInput.value = Intl.NumberFormat("es-CO", { style: "currency", currency: "COP" }).format(totalPrice)
    totalInput.value = Number(totalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })

    if (quantity >= 0 || quantity == "") toggleItemCart(meatType, name, quantity, totalPrice, id, unit)

  }

  async function toggleItemCart(meatType, name, quantity, price, id, unit) {

    let indexOfItem
    let checkOfCartItem = true
    let TypeOfCut = document.getElementById("TypeOfCut-" + id).value
    const productContainer = document.getElementById("Product-" + id)

    const checkCart = Cart[meatType].length > 0 ?
      await Cart[meatType].forEach((item, index) => {
        if (item.name === name) {// Si es false significa que el item ya está registrado - y se dentendrá el forEach
          indexOfItem = index
          checkOfCartItem = false
          return false
        }
        return true
      }) : true

    if (!checkOfCartItem) {

      if (quantity == 0 || quantity == "") {

        openNotification(getMessageNotification(Cart[meatType][indexOfItem].name, quantity, Cart[meatType][indexOfItem].price), "product_deleted")
        // productContainer.className = "Form-body-section1-food-body-item"

        Cart[meatType].splice(indexOfItem, indexOfItem === 0 ? indexOfItem + 1 : indexOfItem)

        await setCart(prevState => { return { ...prevState, [`${meatType}`]: [...Cart[meatType]] } })


      } else {

        Cart[meatType][indexOfItem].quantity = quantity
        Cart[meatType][indexOfItem].price = price
        await setCart(prevState => { return { ...prevState, [`${meatType}`]: [...prevState[meatType]] } })
        openNotification(getMessageNotification(Cart[meatType][indexOfItem].name, quantity, Cart[meatType][indexOfItem].price), "product_updated")

      }

    } else {
      if (quantity == 0 || quantity == "") {

        return false

      } else {
        // Añadir TypeOfCut si está ya seleccionado
        // productContainer.className += " productSelected"
        await setCart(prevState => { return { ...prevState, [`${meatType}`]: [...prevState[meatType], { name: name, quantity: quantity, price: price, TypeOfCut: TypeOfCut, unit: unit }] } })
        // setMeetTypeList({...meatTypeList, [`${meetType}`]: [...meatTypeList[``]]})
        openNotification(getMessageNotification(name, quantity, price), "product_added")

      }

    }


  }


  async function changeMeetCut(e, meatType, name) {
    let indexOfItem
    let checkOfCartItem = true
    let TypeOfCut = e.target.value

    const checkCart = Cart[meatType].length > 0 ?
      await Cart[meatType].forEach((item, index) => {
        if (item.name === name) {// Si es false significa que el item ya está registrado - y se dentendrá el forEach
          indexOfItem = index
          checkOfCartItem = false
          return false
        }
        return true
      }) : true

    if (!checkOfCartItem) {
      Cart[meatType][indexOfItem].TypeOfCut = TypeOfCut
      setCart(prevState => { return { ...prevState, [`${meatType}`]: [...prevState[meatType]] } })

    } else {

      return false // SI no está registrado aún no cambiará nada

    }
  }

  const getLimitByDate = (value) => {

    const dateLocal = value.format("YYYY-MM-DD")
    const url = host + dir_api_name + "/api_consult_ordersLimit.php"
    const query = `?date=${dateLocal}`

    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    let miInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    };

    openNotification("Consultando fecha", "consult_date")

    return fetch(url + query, miInit).then((response) => {
      if (response.ok) {
        return response.text();
      }

      else { throw response };
    }).catch((err) => console.error(err))
  }

  const RegisterUserData = (e, name) => {

    let value

    if (name === "date") {

      value = e

      getLimitByDate(value).then(

        res => {

          console.log("El limite es de: " + res)

          if (Number(res) > 0 || res === "") {

            setUserData({
              ...userData,
              [`${name}`]: value.format("YYYY-MM-DD")
            })

            openNotification("El día: " + value.format("YYYY-MM-DD") + " se encuentra disponible.", "date_available")

          } else if (res === "0" || res < 0) {

            openNotification("Lo sentimos el día: " + value.format("YYYY-MM-DD") + " no esta disponible en este momento.", "date_unavailable")

            document.getElementById("userData-date").focus();

            setUserData({
              ...userData,
              [`${name}`]: ""
            })

          }

        }
      )

    } else {

      value = e.target.value

      setUserData({
        ...userData,
        [`${name}`]: encodeURI(escape("" + value + ""))
      })

    }

  }

  const LoadMeets = (meatType, query) => {
    let headers = new Headers();

    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);

    let miInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    };

    errorCount += 1

    return fetch(host + dir_api_name + '/consult_products.php?type=' + query, miInit).then((response) => {
      if (response.ok) return response.json()

      else { throw "Error" };

    }).then((response) => {
      setMeetTypeList((prevState) => { return { ...prevState, [`${meatType}`]: response } })
    })
      .catch(err => {
        if (errorCount < 2) {
          openNotification("Error reintentando...", "error_reload")
          console.log(err)
          LoadMeets(meatType, query)
        } else if (errorCount === 3) {
          openNotification(`Ha ocurrido un error al cargar ${meatType} :(. Por favor verifique su conexión a internet, si el error persiste contecte con nosotros.`, "error_finish")
        }
      })
  }


  async function register_order() {
    let status = true
    await document.querySelectorAll(".itemsForm").forEach(item => {
      if (item.value === "") {

        item.focus();

        status = false;

        return false;

      } else if (userData.date === "") {
        document.getElementById("userData-date").focus();

        status = false;

        return false;
      } else if (userData.idDirection === "") {

        document.getElementById("userData-location").focus();

        status = false;

        return false;
      }

    })
    if (status) {
      let headers = new Headers();

      headers.append('Content-Type', 'application/json');
      headers.append('Origin', host);

      let miInit = {
        method: 'GET',
        headers: headers,
        mode: 'cors',
      };

      let order = JSON.stringify(Cart)

      let user_data = JSON.stringify(userData)

      let device_data_text = JSON.stringify(device_data)


      // console.log(device_data)



      fetch(`${host}${dir_api_name}/api_register_order.php?order=${order}&user_data=${user_data}&total=${total}&device_data=${device_data_text}`, miInit).then((response) => {
        if (response.ok) return response.text()

        else { throw "Error" };

      }).catch(err => console.log(err))
        .then((response) => {

          let id = response

          id = id.replace(/\+/gi, "°")

          id = id.replace(/\//gi, "^")

          // id = escape(id)

          let orderId = host + "Registro/" + id + "/"

          orderId = encodeURI(orderId)

          window.location.href = 'https://api.whatsapp.com/send?phone=5742041077&text=Ya%20cotice%20mi%20pedido%20en%20%2ASantas%20Carnitas%2A%0A%0AAhora%20quisiera%20fuese%20procesado%20por%20un%20asesor.%0A%0AEste%20es%20mi%20pedido%3A%0A%0A' + orderId
        })
    } else {
      if (step === 2) { alert("Por favor llene todos los campos"); }
    }
  }

  const collapseAll = () => {

    setSectionsCollapseStatus({
      pig: 'collapsed',
      beef: 'collapsed',
      chicken: 'collapsed',
      fish: 'collapsed',
      sausages: 'collapsed',
      cravings: 'collapsed',
      combos: 'collapsed'
    })
    window.scrollTo(0, window.screen.height)
    document.querySelectorAll(".itemsForm").forEach(item => {
      item.value = ""
    })
  }

  const checkPrice = () => {

    if (Number(total) < 30000) {

      alert("El pedido debe ser mayor o igual a 30.000 ")

      return false

    }

    else return true

  }

  const setStepToOne = () => {

    setModal({ visible: true }); setStep(1);

  }

  const setStepToTwo = () => {

    setStep(2);

    window.scrollTo(0, 0);


    setModal({
      visible: false,
    });

    collapseAll()

  }

  const checkStep = () => {

    if (step === 0 && total > 0) {

      if (checkPrice()) {

        setStepToOne()

      }

      return false
    }

    else if (step === 1 && total > 0) {

      setStepToTwo()

      return false

    }

    else if (step === 2 && total > 0) {

      register_order()

      return false

    }

    else { alert("Por favor seleccione al menos un producto"); }

  }
  /////////////////// END Functions ///////////////////



  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



  /////////////////// START Effescts ///////////////////


  useEffect(() => {
    let summaryPig = 0
    let summaryBeef = 0
    let summaryChicken = 0
    let summaryFish = 0
    let summarySausages = 0
    let summaryCravings = 0
    let summaryCombos = 0

    Cart.pig.forEach(item => {
      summaryPig += item.price
    })
    Cart.beef.forEach(item => {
      summaryBeef += item.price
    })
    Cart.chicken.forEach(item => {
      summaryChicken += item.price
    })
    Cart.fish.forEach(item => {
      summaryFish += item.price
    })
    Cart.sausages.forEach(item => {
      summarySausages += item.price
    })
    Cart.cravings.forEach(item => {
      summaryCravings += item.price
    })
    Cart.combos.forEach(item => {
      summaryCombos += combos[item.index].price * item.quantity
    })
    setSubTotals(
      {
        ...SubTotals,
        pig: summaryPig,
        beef: summaryBeef,
        chicken: summaryChicken,
        fish: summaryFish,
        sausages: summarySausages,
        cravings: summaryCravings,
        combos: summaryCombos
      }
    )
    setTotal(summaryPig + summaryBeef + summaryChicken + summaryFish + summarySausages + summaryCravings + summaryCombos + Number(Cart.delivery_price))

  }, [Cart]) // Use state by cart


  useEffect(() => {

    LoadMeets("pig", "cerdo")
      .then(() => LoadMeets("beef", "res")
        .then(() => LoadMeets("chicken", "pollo")
          .then(() => LoadMeets("fish", "PESCADOS Y MARISCOS")
            .then(() => LoadMeets("sausages", "CARNES FRIAS")
              .then(() => LoadMeets("cravings", "ANTOJOS"))))))

    setDeviceData({
      appCodeName: navigator.appCodeName,
      appName: navigator.appName,
      appVersion: navigator.appVersion,
      connection: navigator.connection,
      cookieEnabled: navigator.cookieEnabled,
      deviceMemory: navigator.deviceMemory,
      hardwareConcurrency: navigator.hardwareConcurrency,
      language: navigator.language,
      languages: navigator.languages,
      platform: navigator.platform,
      userAgent: navigator.userAgent,
      vendor: navigator.vendor

    })

  }, [])

  useEffect(() => {
    if (step === 2) document.getElementById("userData-id").focus();
  }, [step])


  /////////////////// END Effects ///////////////////




  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////




  /////////////////// START Component Content ///////////////////

  return (

    <div className="Form">
      <Header showCatalogue={true} />
      {/* <section className="Form-banner">
        <a href="https://www.indiacompany.co" target="_blanck"><img src={Banner} alt="" /></a>
      </section> */}

      <section className="Form-body">

        <div id="stage-0" data-stage="0" className="Form-body-section1" style={{ display: step === 0 || step === 1 ? "block" : "none" }}>

          {/* <PrintCombos sectionsCollapseStatus={sectionsCollapseStatus}

            setSectionsCollapseStatus={setSectionsCollapseStatus}
            combosToPrint={combos}
            setCombos={setCombos}
            changeQuantityItem={changeQuantityItem}
            changeMeetCut={changeMeetCut}
            SubTotals={SubTotals}
            Cart={Cart} setCart={setCart}
            openNotification={openNotification}
          /> */}

          <section className="Form-body-section1-food pig" style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
              ...sectionsCollapseStatus,
              pig: sectionsCollapseStatus.pig === "collapsed" ? "open" : 'collapsed'
            })}>

              <p>Cerdo</p>

              <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus.pig} src={LogoDown} alt="Imagen no disponible"
                onClick={() => setSectionsCollapseStatus({
                  ...sectionsCollapseStatus,
                  pig: sectionsCollapseStatus.pig === "collapsed" ? "open" : 'collapsed'
                })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus.pig} id="section-pig">
              <header className="Form-body-section1-food-body-header">
                <p>Cantidad</p>
                <p>¿Cómo lo quiere?</p>
                <p>Total</p>
              </header>
              {
                meatTypeList.pig.length === 0 ? "Cargando..." :
                  sectionsCollapseStatus.pig === "open" ?
                    meatTypeList.pig.map((item, index) =>
                      <section className="Form-body-section1-food-body-item" id={"Product-Pig-" + item.id}>
                        <div className="item-section1">
                          <p>{item.plu} - {item.descripcion} - <b>{Number(item.precio_publico).toLocaleString("de-AT", { style: "currency", currency: "USD" }) + " * " + item.unidad_medida}</b></p>
                        </div>
                        <div className="item-section2">
                          <input type="number" className="Quantities-Pig" id={"Quantity-Pig-" + item.id} min="0" max="99" pattern="[0-9]" step="1" onInput={
                            (e) => {
                              // let event = e
                              // if (isNaN(Number(event.target.value))) {
                              //   e.preventDefault()
                              // } else {
                              changeQuantityItem(e, "Pig-" + item.id, "pig", item.descripcion, item.precio_publico, item.unidad_medida, index)
                              // }
                            }
                          } placeholder="Cantidad" />
                          {
                            item.unidad_medida === "UNIDAD" ?
                              <select>
                                <option value="">¿Cómo lo quiere?</option>
                              </select>
                              :
                              <select onChange={(e) => changeMeetCut(e, "pig", item.descripcion)} id={"TypeOfCut-Pig-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                {
                                  item.presentacion !== "" ? JSON.parse(item.presentacion).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                    PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)
                                }
                              </select>
                          }
                          <input placeholder="0,00" id={"Total-Pig-" + item.id} readOnly />

                        </div>

                      </section>
                    )
                    : null

              }

            </section>
            <footer className="Form-body-section1-food-card">
              <p>Sub Total: <b>{Number(SubTotals.pig).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            </footer>
          </section>
          {/*  Fin section Pig */}
          <section className="Form-body-section1-food beef" style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
              ...sectionsCollapseStatus,
              beef: sectionsCollapseStatus.beef === "collapsed" ? "open" : 'collapsed'
            })}>

              <p>Res</p>

              <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus.beef} src={LogoDown} alt="Imagen no disponible"
                onClick={() => setSectionsCollapseStatus({
                  ...sectionsCollapseStatus,
                  beef: sectionsCollapseStatus.beef === "collapsed" ? "open" : 'collapsed'
                })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus.beef} id="section-beef">
              <header className="Form-body-section1-food-body-header">
                <p>Cantidad</p>
                <p>¿Cómo lo quiere?</p>
                <p>Total</p>
              </header>
              {
                meatTypeList.beef.length === 0 ? "Cargando..." :

                  sectionsCollapseStatus.beef === "open" ?

                    meatTypeList.beef.map((item) => {
                      return <section className="Form-body-section1-food-body-item">
                        <div className="item-section1">
                          <p>{item.plu} - {item.descripcion} - <b>{Number(item.precio_publico).toLocaleString("de-AT", { style: "currency", currency: "USD" }) + " * " + item.unidad_medida}</b></p>
                        </div>
                        <div className="item-section2">
                          <input type="number" className="Quantities-Beef" id={"Quantity-Beef-" + item.id} min="0" max="99" onChange={(e) => changeQuantityItem(e, "Beef-" + item.id, "beef", item.descripcion, item.precio_publico, item.unidad_medida)} placeholder="Cantidad" />
                          {
                            item.unidad_medida === "UNIDAD" || item.descripcion === "SOLOMITO" ?
                              <select onChange={(e) => changeMeetCut(e, "beef", item.descripcion)} id={"TypeOfCut-Beef-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                <option value="Entero">Entero</option>
                              </select>
                              :
                              <select onChange={(e) => changeMeetCut(e, "beef", item.descripcion)} id={"TypeOfCut-Beef-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                {item.presentacion !== "" ? JSON.parse(item.presentacion).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                  PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)}
                              </select>
                          }
                          <input placeholder="0,00" id={"Total-Beef-" + item.id} readOnly />
                        </div>
                      </section>
                    })

                    : null

              }

            </section>
            <footer className="Form-body-section1-food-card">
              <p>Sub Total: <b>{Number(SubTotals.beef).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            </footer>
          </section>
          {/*  Fin section Beef */}
          <section className="Form-body-section1-food chicken" style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
              ...sectionsCollapseStatus,
              chicken: sectionsCollapseStatus.chicken === "collapsed" ? "open" : 'collapsed'
            })}>

              <p>Pollo</p>

              <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus.chicken} src={LogoDown} alt="Imagen no disponible"
                onClick={() => setSectionsCollapseStatus({
                  ...sectionsCollapseStatus,
                  chicken: sectionsCollapseStatus.chicken === "collapsed" ? "open" : 'collapsed'
                })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus.chicken} id="section-chicken">
              <header className="Form-body-section1-food-body-header">
                <p>Cantidad</p>
                <p>¿Cómo lo quiere?</p>
                <p>Total</p>
              </header>
              {
                meatTypeList.chicken.length === 0 ? "Cargando..." :

                  sectionsCollapseStatus.chicken === "open" ?

                    meatTypeList.chicken.map((item) => {
                      return <section className="Form-body-section1-food-body-item">
                        <div className="item-section1">
                          <p>{item.plu} - {item.descripcion} - <b>{Number(item.precio_publico).toLocaleString("de-AT", { style: "currency", currency: "USD" }) + " * " + item.unidad_medida}</b></p>
                        </div>
                        <div className="item-section2">
                          <input type="number" className="Quantities-chicken" id={"Quantity-Chicken-" + item.id} min="0" onChange={(e) => changeQuantityItem(e, "Chicken-" + item.id, "chicken", item.descripcion, item.precio_publico, item.unidad_medida)} placeholder="Cantidad" />
                          {
                            item.unidad_medida === "UNIDAD" ?
                              <select id={"TypeOfCut-Chicken-" + item.id}>
                                <option value="" >¿Cómo lo quiere?</option>
                              </select>
                              :
                              <select onChange={(e) => changeMeetCut(e, "chicken", item.descripcion)} id={"TypeOfCut-Chicken-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                {item.presentacion !== "" ? JSON.parse(item.presentacion).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                  PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)}
                              </select>
                          }
                          <input placeholder="0,00" id={"Total-Chicken-" + item.id} readOnly />
                        </div>
                      </section>
                    })

                    : null

              }

            </section>
            <footer className="Form-body-section1-food-card">
              <p>Sub Total: <b>{Number(SubTotals.chicken).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            </footer>
          </section>
          {/*  Fin section Chicken */}
          <section className="Form-body-section1-food fish" style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
              ...sectionsCollapseStatus,
              fish: sectionsCollapseStatus.fish === "collapsed" ? "open" : 'collapsed'
            })}>

              <p>Pescados Y Mariscos</p>

              <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus.fish} src={LogoDown} alt="Imagen no disponible"
                onClick={() => setSectionsCollapseStatus({
                  ...sectionsCollapseStatus,
                  fish: sectionsCollapseStatus.fish === "collapsed" ? "open" : 'collapsed'
                })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus.fish} id="section-fish">
              <header className="Form-body-section1-food-body-header">
                <p>Cantidad</p>
                <p>¿Cómo lo quiere?</p>
                <p>Total</p>
              </header>
              {
                meatTypeList.fish.length === 0 ? "Cargando..." :

                  sectionsCollapseStatus.fish === "open" ?

                    meatTypeList.fish.map((item) => {
                      return <section className="Form-body-section1-food-body-item">
                        <div className="item-section1">
                          <p>{item.plu} - {item.descripcion} - <b>{Number(item.precio_publico).toLocaleString("de-AT", { style: "currency", currency: "USD" }) + " * " + item.unidad_medida}</b></p>
                        </div>
                        <div className="item-section2">
                          <input type="number" className="Quantities-Fish" id={"Quantity-Fish-" + item.id} min="0" onChange={(e) => changeQuantityItem(e, "Fish-" + item.id, "fish", item.descripcion, item.precio_publico, item.unidad_medida)} placeholder="Cantidad" />
                          {
                            item.unidad_medida === "UNIDAD" ?
                              <select onChange={(e) => changeMeetCut(e, "pig", item.descripcion)} id={"TypeOfCut-Fish-" + item.id}>

                                <option value="">¿Cómo lo quiere?</option>
                              </select>
                              :
                              <select onChange={(e) => changeMeetCut(e, "pig", item.descripcion)} id={"TypeOfCut-Fish-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                {item.presentacion !== "" ? JSON.parse(item.presentacion).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                  PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)}
                              </select>
                          }
                          <input placeholder="0,00" id={"Total-Fish-" + item.id} readOnly />
                        </div>
                      </section>
                    })

                    : null

              }

            </section>
            <footer className="Form-body-section1-food-card">
              <p>Sub Total: <b>{Number(SubTotals.fish).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            </footer>
          </section>
          {/*  Fin section Fish */}
          <section className="Form-body-section1-food sausages" style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
              ...sectionsCollapseStatus,
              sausages: sectionsCollapseStatus.sausages === "collapsed" ? "open" : 'collapsed'
            })}>

              <p>Carnes Frias</p>

              <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus.sausages} src={LogoDown} alt="Imagen no disponible"
                onClick={() => setSectionsCollapseStatus({
                  ...sectionsCollapseStatus,
                  sausages: sectionsCollapseStatus.sausages === "collapsed" ? "open" : 'collapsed'
                })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus.sausages} id="section-sausages">
              <header className="Form-body-section1-food-body-header">
                <p>Cantidad</p>
                <p>¿Cómo lo quiere?</p>
                <p>Total</p>
              </header>
              {
                meatTypeList.sausages.length === 0 ? "Cargando..." :

                  sectionsCollapseStatus.sausages === "open" ?

                    meatTypeList.sausages.map((item) => {
                      return <section className="Form-body-section1-food-body-item">
                        <div className="item-section1">
                          <p>{item.plu} - {item.descripcion} - <b>{Number(item.precio_publico).toLocaleString("de-AT", { style: "currency", currency: "USD" }) + " * " + item.unidad_medida}</b></p>
                        </div>
                        <div className="item-section2">
                          <input type="number" className="Quantities-Sausages" id={"Quantity-Sausages-" + item.id} min="0" onChange={(e) => changeQuantityItem(e, "Sausages-" + item.id, "sausages", item.descripcion, item.precio_publico, item.unidad_medida)} placeholder="Cantidad" />
                          {
                            item.unidad_medida === "UNIDAD" ?
                              <select id={"TypeOfCut-Sausages-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                              </select>
                              :
                              <select onChange={(e) => changeMeetCut(e, "pig", item.descripcion)} id={"TypeOfCut-Sausages-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                {item.presentacion !== "" ? JSON.parse(item.presentacion).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                  PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)}
                              </select>
                          }
                          <input placeholder="0,00" id={"Total-Sausages-" + item.id} readOnly />
                        </div>
                      </section>
                    })

                    : null

              }

            </section>
            <footer className="Form-body-section1-food-card">
              <p>Sub Total: <b>{Number(SubTotals.sausages).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            </footer>
          </section>
          {/*  Fin section Sausuges */}
          <section className="Form-body-section1-food cravings" style={{ marginTop: 3 + "vh" }}>

            <header title="Mostrar todo" className="Form-body-section1-food-header" onClick={() => setSectionsCollapseStatus({
              ...sectionsCollapseStatus,
              cravings: sectionsCollapseStatus.cravings === "collapsed" ? "open" : 'collapsed'
            })}>

              <p>Antojos</p>

              <img title="Mostrar todo" className={"logo-collapse logo-" + sectionsCollapseStatus.cravings} src={LogoDown} alt="Imagen no disponible"
                onClick={() => setSectionsCollapseStatus({
                  ...sectionsCollapseStatus,
                  cravings: sectionsCollapseStatus.cravings === "collapsed" ? "open" : 'collapsed'
                })} />

            </header>

            <section className={"Form-body-section1-food-body " + sectionsCollapseStatus.cravings} id="section-cravings">
              <header className="Form-body-section1-food-body-header">
                <p>Cantidad</p>
                <p>¿Cómo lo quiere?</p>
                <p>Total</p>
              </header>
              {
                meatTypeList.cravings.length === 0 ? "Cargando..." :

                  sectionsCollapseStatus.cravings === "open" ?

                    meatTypeList.cravings.map((item) => {
                      return <section className="Form-body-section1-food-body-item">
                        <div className="item-section1">
                          <p>{item.plu} - {item.descripcion} - <b>{Number(item.precio_publico).toLocaleString("de-AT", { style: "currency", currency: "USD" }) + " * " + item.unidad_medida}</b></p>
                        </div>
                        <div className="item-section2">
                          <input type="number" className="Quantities-Cravings" id={"Quantity-Cravings-" + item.id} min="0" onChange={(e) => changeQuantityItem(e, "Cravings-" + item.id, "cravings", item.descripcion, item.precio_publico, item.unidad_medida)} placeholder="Cantidad" />
                          {
                            item.unidad_medida === "UNIDAD" ?
                              <select id={"TypeOfCut-Cravings-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                              </select>
                              :
                              <select onChange={(e) => changeMeetCut(e, "cravings", item.descripcion)} id={"TypeOfCut-Cravings-" + item.id}>
                                <option value="">¿Cómo lo quiere?</option>
                                {item.presentacion !== "" ? JSON.parse(item.presentacion).map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null) :
                                  PresentationsList.map((item, index) => item.selectable ? <option value={item.name}> {item.name} </option> : null)
                                }
                              </select>
                          }
                          <input placeholder="0,00" id={"Total-Cravings-" + item.id} readOnly />
                        </div>
                      </section>
                    })

                    : null

              }

            </section>
            <footer className="Form-body-section1-food-card">
              <p>Sub Total: <b>{Number(SubTotals.cravings).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
            </footer>
          </section>
          {/*  Fin section Craving */}
        </div>

        {
          step === 2 ?
            <div id="stage-1" className="Form-body-section2" data-stage="1" style={{ display: step === 2 ? "block" : "none" }}>
              <h2>Reserva tu pedido</h2>
              <form>
                <div className="item">
                  <label>Cedula</label>
                  <input className="itemsForm" type="number" id="userData-id" onChange={(e,) => RegisterUserData(e, "id")} />
                </div>
                <div className="flex">
                  <div>
                    <label>Nombre</label>
                    <input className="itemsForm" type="text" id="userData-name" onChange={(e) => RegisterUserData(e, "name")} />
                  </div>
                  <div>
                    <label>Apellido</label>
                    <input className="itemsForm" type="text" id="userData-last_name" onChange={(e,) => RegisterUserData(e, "last_name")} />
                  </div>
                </div>
                <div className="item">
                  <label>Correo Electrónico</label>
                  <input className="itemsForm" name="email" type="email" id="userData-email" onChange={(e,) => RegisterUserData(e, "email")} />
                </div>
                <div className="item">
                  <label>Celular</label>
                  <input className="itemsForm" name="cell" type="number" id="userData-cell" onChange={(e,) => RegisterUserData(e, "cell")} />
                </div>
                <div className="item">
                  <label>Telefono fijo</label>
                  <input className="itemsForm" name="tel" type="number" id="userData-tel" onChange={(e,) => RegisterUserData(e, "tel")} />
                </div>
                <div className="item">
                  <label>Dirección</label>
                  <input className="itemsForm" type="text" id="userData-address" onChange={(e,) => RegisterUserData(e, "address")} style={{ width: "100%" }} />
                </div>
                <div className="item">

                  <label>Municipio, Barrio y Comuna</label>

                  <Address setUserData={setUserData} setCart={setCart} />
                  {/* <div>
                    <label>Municipio</label>
                    <input className="itemsForm" type="text" id="userData-city" onChange={(e,) => RegisterUserData(e, "city")} />
                  </div>
                  <div>
                    <label>Barrio</label>
                    <input className="itemsForm" type="text" id="userData-street" onChange={(e,) => RegisterUserData(e, "street")} />
                  </div> */}
                </div>
                <div className="item">
                  <label>Punto de referencia</label>
                  <input className="itemsForm" type="text" id="userData-point_reference" onChange={(e,) => {
                    if (escape(e.target.value) === "%0A") {
                      e.preventDefault();
                      console.log('prevented');
                      return false;
                    } else {
                      // console.log(escape(e.target.value))
                      RegisterUserData(e, "point_reference")
                    }
                  }} style={{ width: "100%" }} />
                </div>
                <div className="item">
                  <label>¿Cuando quieres que te llegue?</label>
                  <DatePicker className="itemsForm" type="date" id="userData-date" allowClear={false} locale={locale} autoComplete={"off"} value={userData.date !== "" ? moment(userData.date) : ""} onChange={(e) => RegisterUserData(e, "date")} />
                </div>
                <div>
                  <label>¿Como quieres pagar?</label>
                  <select className="itemsForm" id="userData-payment_method" onChange={(e,) => RegisterUserData(e, "payment_method")}>
                    <option value="">  </option>
                    <option value="Efectivo"> Efectivo </option>
                    {/*
                        <option value="Tarjeta Debito"> Tarjeta Débito </option>
                        <option value="Tarjeta Credito"> Tarjeta Crédito </option> 
                    */}
                    <option value="Transferencia Electronica"> Transferencia Electrónica </option>
                    <option value="Código QR"> Código QR </option>
                  </select>
                </div>
                <div>
                  {/* <label form="terms">
                <input type="checkbox" className="itemForm" id="terms" name="terms" />
                Aceptar <a href="#">ternimos</a> y <a href="#">condiciones</a>
              </label> */}
                </div>
              </form>
              <CartSummary Cart={Cart} combos={combos} total={total} title={"Resumen"} />
            </div>
            : null
        }

      </section>


      <section className="StepButton" id={step < 2 ? "StepButtonStage0" : ""}>

        <button className="Back" onClick={() => { setStep(0); collapseAll() }} // Event Listener Click END---------------------------
          style={{ display: step === 2 ? "block" : "none" }}
        >
          Volver
        </button>
        <button onClick={() => checkStep()} // Event Listener Click END---------------------------

          style={{ boxShadow: step === 2 ? "-1.5px 0px 3px rgba(143, 62, 0, 0.5)" : "none" }}
        >

          {
            step === 0 ?
              <p style={{ margin: 0, }}>Mostrar carrito <span style={{ color: "#fafafa" }}>| </span>
                <span style={{ color: "white" }}>Total : {<b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>}</span>
              </p>
              : step === 1 ?
                <p style={{ margin: 0, }}>Siguiente <span style={{ color: "#fafafa" }}>| </span>
                  <span style={{ color: "white" }}>Total : {<b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>}</span>
                </p>
                :
                <p style={{ margin: 0, }}>Finalizar pedido <span style={{ color: "#fafafa" }}>| </span>
                  <span style={{ color: "white" }}>Total : {<b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>}</span>
                </p>
          }
        </button>

      </section>

      {/* <section className="Form-Loading">
        <img src={Logo} alt="Santas Carnitas" />
        <p>{loading_status}</p>
      </section> */}
      <Modal
        title=""
        visible={modal.visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Siguiente | finalizar pedido"}
        cancelText={"Seguir comprando"}
        style={{ marginBottom: 70 + "px" }}
        className="Modal-carrito"
      >
        {step === 1 ? <CartSummary Cart={Cart} combos={combos} total={total} title={"Carrito"} /> : null}

      </Modal>
      <Footer />
    </div>
  );
}

export default App;
