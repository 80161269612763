import React, { useState, useEffect } from 'react'
import Logo from './../../src/Assets/cropped-logosocial-300x300.png'

import './../dist/css/main.min.css'
import { useParams } from 'react-router-dom'
import { host, dir_api_name } from '../api_vars'
import Header from './header'
import { combosPropsVer2 } from './Props/combos'


export default function API_Cart() {

  const { id } = useParams()
  const [step, setStep] = useState(1)
  const [Cart, setCart] = useState({
    pig: [],
    beef: [],
    chicken: [],
    fish: [],
    sausages: [],
    cravings: [],
    combos: []
  })
  const [total, setTotal] = useState(0)
  const [userData, setUserData] = useState({
    name: "",
    last_name: "",
    id: "",
    cell: "",
    tel: "",
    address: "",
    city: "",
    street: "",
    point_reference: "",
    date: "",
    hour: "",
    minute: "",
    am_pm: "",
    payment_method: ""
  })
  const [combos, setCombos] = useState(
    [
      {
        title: "Combo 1",
        collapseStatus: 'collapsed',
        price: 49700,
        id: "cm1",
        content: [
          {
            id: "1",
            description: "CAÑON",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          },
          {
            id: "2",
            description: "PIERNA TRASERA",
            lines: "res",
            quantity: "2",
            unit: "LIBRAS",
            price: "9000",
            TypeOfCut: ""
          },
          {
            id: "3",
            description: "MANERO",
            lines: "res",
            quantity: "1",
            unit: "LIBRA",
            price: "3700",
            TypeOfCut: ""
          },
          {
            id: "4",
            description: "BARRIGUERO",
            lines: "res",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          },
          {
            id: "5",
            description: "PAPADA",
            lines: "res",
            quantity: "1",
            unit: "LIBRA",
            price: "3350",
            TypeOfCut: ""
          },
          {
            id: "6",
            description: "COSTILLA",
            lines: "res",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          },
          {
            id: "7",
            description: "ESPINAZO",
            lines: "res",
            quantity: "1",
            unit: "LIBRA",
            price: "3000",
            TypeOfCut: ""
          },
          {
            id: "8",
            description: "PEZUÑA",
            lines: "res",
            quantity: "1",
            unit: "LIBRA",
            price: "1600",
            TypeOfCut: ""
          },
          {
            id: "9",
            description: "PECHUGA",
            lines: "pollo",
            quantity: "2",
            unit: "LIBRAS",
            price: "5000",
            TypeOfCut: ""
          },
          {
            id: "10",
            description: "PESCADO BASA",
            lines: "PESCADOS Y MARISCOS",
            quantity: "1",
            unit: "LIBRA",
            price: "4800",
            TypeOfCut: ""
          },
          {
            id: "11",
            description: "CHORIZO CERDO",
            lines: "EMBUTIDOS",
            quantity: "3",
            unit: "UNIDADES",
            price: "2000",
            TypeOfCut: ""
          }
        ]
      },
      {
        title: "Combo 2",
        collapseStatus: 'collapsed',
        price: 50650,
        id: "cm2",
        content: [
          {
            id: "1",
            description: "PIERNA TRASEA",
            lines: "RES",
            quantity: "2",
            unit: "LIBRAS",
            price: "9000",
            TypeOfCut: ""
          },
          {
            id: "2",
            description: "BARRIGUERO",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          }, {
            id: "3",
            description: "PAPADA",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "3350",
            TypeOfCut: ""
          }, {
            id: "4",
            description: "COSTILLA",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          }, {
            id: "5",
            description: "ESPINAZO",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "3000",
            TypeOfCut: ""
          }, {
            id: "6",
            description: "PEZUÑA",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "1600",
            TypeOfCut: ""
          }, {
            id: "7",
            description: "PECHUGA",
            lines: "POLLO",
            quantity: "2",
            unit: "LIBRAS",
            price: "5000",
            TypeOfCut: ""
          }
          , {
            id: "8",
            description: "PESCADO BASA",
            lines: "PESCADOS Y MARISCOS",
            quantity: "1",
            unit: "LIBRA",
            price: "4800",
            TypeOfCut: ""
          }, {
            id: "9",
            description: "TILAPIA",
            lines: "PESCADOS Y MARISCOS",
            quantity: "2",
            unit: "LIBRAS",
            price: "104000",
            TypeOfCut: ""
          }, {
            id: "10",
            description: "CHORIZO DE CERDO",
            lines: "RES",
            quantity: "3",
            unit: "UNIDADES",
            price: "2000",
            TypeOfCut: ""
          }
        ]
      },
      {
        title: "Combo 3",
        collapseStatus: 'collapsed',
        price: 51800,
        id: "cm3",
        content: [
          {
            id: "1",
            description: "PIERNA TRASEA",
            lines: "RES",
            quantity: "2",
            unit: "LIBRAS",
            price: "9000",
            TypeOfCut: ""
          },
          {
            id: "2",
            description: "BARRIGUERO",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          },
          {
            id: "3",
            description: "PAPADA",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "3350",
            TypeOfCut: ""
          },
          {
            id: "4",
            description: "PECHUGA",
            lines: "POLLO",
            quantity: "2",
            unit: "LIBRAS",
            price: "5000",
            TypeOfCut: ""
          },
          {
            id: "5",
            description: "TILAPIA",
            lines: "PESCADOS Y MARISCOS",
            quantity: "2",
            unit: "LIBRAS",
            price: "10400",
            TypeOfCut: ""
          },
          {
            id: "6",
            description: "PESCADO BASA",
            lines: "PESCADOS Y MARISCOS",
            quantity: "1",
            unit: "LIBRA",
            price: "4800",
            TypeOfCut: ""
          },
          {
            id: "7",
            description: "SANCOCHO",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "6500",
            TypeOfCut: ""
          },
          {
            id: "8",
            description: "CHORIZO CERDO",
            lines: "EMBUTIDOS",
            quantity: "3",
            unit: "UNIDADES",
            price: "2000",
            TypeOfCut: ""
          },
          {
            id: "9",
            description: "MOLIDA MIXTA",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5000",
            TypeOfCut: ""
          }
        ]
      },
      {
        title: "Combo 4",
        collapseStatus: 'collapsed',
        price: 56850,
        id: "cm4",
        content: [
          {
            id: "1",
            description: "PECHUGA",
            lines: "POLLO",
            quantity: "2",
            unit: "LIBRAS",
            price: "5000",
            TypeOfCut: ""
          },
          {
            id: "2",
            description: "CAÑON",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          }, {
            id: "3",
            description: "PIERNA GRAMEADA CERO GRASA",
            lines: "RES",
            quantity: "2",
            unit: "LIBRAS",
            price: "11000",
            TypeOfCut: ""
          }, {
            id: "4",
            description: "BARRIGUERO",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          }, {
            id: "5",
            description: "COSTILLA",
            lines: "RES",
            quantity: "1",
            unit: "LIBRA",
            price: "5750",
            TypeOfCut: ""
          }, {
            id: "6",
            description: "CHORIZO CERDO",
            lines: "EMBUTIDOS",
            quantity: "3",
            unit: "UNIDADES",
            price: "2000",
            TypeOfCut: ""
          }, {
            id: "7",
            description: "RES ESPECIAL GRAMEADA CERO GRASA",
            lines: "RES",
            quantity: "2",
            unit: "LIBRAS",
            price: "21600",
            TypeOfCut: ""
          },
        ]
      },
    ]
  ) // Combos

  const quantity = (value) => {
    if (value === undefined) {
      return 1
    }
    else {
      return value
    }
  }

  useEffect(() => {
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Origin', host);
    let miInit = {
      method: 'GET',
      headers: headers,
      mode: 'cors',
    };

    let varName = ""
    let idR = decodeURI(id)
    // idR = idR.replace("°","+")
    // idR = idR.replace("^","/")

    if (isNaN(idR)) varName = "key"; else varName = "orderId"

    idR = encodeURI(idR)

    fetch(`${host}${dir_api_name}/api_consult_order.php?${varName}=${idR}&type=Order`, miInit).then((response) => {

      if (response.ok) return response.json()

      else { throw "Error" };

    }).catch(err => console.log(err))
      .then((response) => {
        response = JSON.parse(`${response}`)

        setCart({ ...Cart, ...response })

        fetch(`${host}${dir_api_name}/api_consult_order.php?${varName}=${idR}&type=user_data`, miInit).then((response) => {
          // fetch(`${host}API/api.php?orderId=${id}&type=user_data`, miInit).then((response) => {
          if (response.ok) return response.json()

          else { throw "Error" };

        }).catch(err => console.log(err))
          .then((response) => {

            response = JSON.parse(`${response}`)
            setUserData(response)

            fetch(`${host}${dir_api_name}/api_consult_order.php?${varName}=${idR}&type=total`, miInit).then((response) => {
              // fetch(`${host}API/api.php?orderId=${id}&type=total`, miInit).then((response) => {

              if (response.ok) return response.text()

              else { throw "Error" };

            }).catch(err => console.log(err))

              .then((response) => {

                setTotal(response)

              })

          })

      })

  }, [])
  return (
    <div className="Form">
      <Header showCatalogue={false} />

      <section className="Form-body Form-API_Cart">

        <div id="stage-1" className="Form-body-section2 Form-API_Cart-section1" data-stage="1" style={{ display: step === 1 ? "block" : "none" }}>
          <h2>Datos del pedido</h2>
          <form>
            <div className="flex">
              <div>
                <label>ID</label>
                <input type="text" id="userData-idOrder" readOnly value={id} />
              </div>
              <div>
                <label>N° Factura</label>
                <input type="text" id="userData-invoiceNumber" readOnly value={userData.invoice_number === undefined ? "Por definir" : userData.invoice_number} />
              </div>
            </div>
            <div className="flex">
              <div>
                <label>Nombre</label>
                <input className="itemsForm" type="text" value={unescape(userData.name)} id="userData-name" readOnly />
              </div>
              <div>
                <label>Apellido</label>
                <input type="text" value={unescape(userData.last_name)} id="userData-last_name" readOnly />
              </div>
            </div>
            <div className="item">
              <label>Cedula</label>
              <input type="text" id="userData-id" readOnly value={unescape(userData.id)} />
            </div>
            <div className="item">
              <label>Correo Electrónico</label>
              <input type="email" id="userData-cell" readOnly value={unescape(userData.email)} />
            </div>
            <div className="item">
              <label>Celular</label>
              <input type="text" id="userData-cell" readOnly value={unescape(userData.cell)} />
            </div>
            <div className="item">
              <label>Telefono fijo</label>
              <input type="text" id="userData-tel" readOnly value={unescape(userData.tel)} />
            </div>
            <div className="item">
              <label>Dirección</label>
              <input type="text" id="userData-address" readOnly value={unescape(userData.address)} />
            </div>
            <div className="flex">
              <div>
                <label>Municipio</label>
                <input type="text" id="userData-city" readOnly value={unescape(userData.city)} />
              </div>
              <div>
                <label>Barrio</label>
                <input type="text" id="userData-street" readOnly value={unescape(userData.street === undefined ? userData.neighborhood : userData.street)} />
              </div>
            </div>
            <div className="item">
              <label>Punto de referencia</label>
              <input type="text" id="userData-point_reference" readOnly value={unescape(userData.point_reference)} />
            </div>
            <div className="item">
              <label>¿Cuando quieres que te llegue?</label>
              <input type="date" id="userData-date" readOnly value={unescape(userData.date)} />
            </div>
            <div>
              <label>¿Como quieres pagar?</label>
              <select id="userData-payment_method" readOnly>
                <option value=""> {unescape(userData.payment_method)} </option>
              </select>
            </div>
          </form>
        </div>
        <footer className="Form-footer Form-footer-api">
          <section className="Form-footer-cart">
            <h2>Carrito</h2>
            <div className="Form-footer-cart-body">
              <ul>
                {
                  Cart.pig.length === 0 && Cart.beef.length === 0 && Cart.chicken.length === 0 && Cart.fish.length === 0 && Cart.sausages.length === 0 && Cart.cravings.length === 0
                    && Cart.combos.length === 0 ?
                    < li style={{ color: "#707070" }}>Vacío</li> : null
                }
                {
                  Cart.pig.map(item =>
                    <li>
                      {item.name} x {item.quantity} {item.unit} - Total : <b>{Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b> <i>{item.TypeOfCut}</i>
                      <br />

                      {/* <b> Peso : </b>

                      <input type="number" placeholder={item.unit === "LIBRA" || item.unit === "LIBRAS" ? (500 * item.quantity) + " Gramos" : "UNIDAD"} readOnly ></input> */}
                    </li>
                  )
                }
                {
                  Cart.beef.length === 0 ? null :
                    Cart.beef.map(item =>
                      <li>
                        {item.name} x {item.quantity} {item.unit} - Total : <b>{Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })} </b> <i>{item.TypeOfCut}</i>
                      </li>
                    )
                }
                {
                  Cart.chicken.length === 0 ? null :
                    Cart.chicken.map(item =>
                      <li>
                        {item.name} x {item.quantity} {item.unit} - Total : <b>{Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })} </b> <i>{item.TypeOfCut}</i>
                      </li>
                    )
                }
                {
                  Cart.fish.length === 0 ? null :
                    Cart.fish.map(item =>
                      <li>
                        {item.name} x {item.quantity} {item.unit} - Total : <b>{Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b> <i>{item.TypeOfCut}</i>
                      </li>
                    )
                }
                {
                  Cart.sausages.length === 0 ? null :
                    Cart.sausages.map(item =>
                      <li>{item.name} x {item.quantity} {item.unit} - Total : <b>{Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })} </b> <i>{item.TypeOfCut}</i></li>
                    )
                }
                {
                  Cart.cravings.length === 0 ? null :
                    Cart.cravings.map(item =>
                      <li>
                        {item.name} x {item.quantity} {item.unit} - Total : <b>{Number(item.finalPrice === undefined ? item.price : item.finalPrice).toLocaleString("de-AT", { style: "currency", currency: "USD" })} </b> {" " + item.TypeOfCut}
                      </li>
                    )
                }
                {
                  Cart.combos.length === null ? null :
                    Cart.combos.map((combo, indexComboinCart) =>
                      combo.verData === undefined ?
                        <li key={indexComboinCart}>
                          {
                            combo.ver === undefined ?
                              <span>{combos[combo.index].title} x {quantity(combo.quantity)} | <b>{Number(combos[combo.index].price * (quantity(combo.quantity))).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></span>
                              : <span>{combosPropsVer2[combo.index].title} x {quantity(combo.quantity)} | <b>{Number(combosPropsVer2[combo.index].price * (quantity(combo.quantity))).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></span>
                          }
                          <ul>
                            {
                              combo.ver === undefined ?
                                combos[combo.index].content.map((item, indexComboInCombos) =>

                                  <li style={{ marginBottom: "1vh" }}>{item.description} x {item.quantity} {item.unit} - Total : {Number(item.finalPrice !== "" ? item.finalPrice : item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} {" " + item.TypeOfCut}|
                                                    {/* <br /> */}
                                    {/* <b> Peso : </b>
                                    <input type="text" id={"combo" + combo.index + indexComboInCombos} value={item.unit === "UNIDAD" || item.unit === "UNIDADES" ? null : item.weight + " Gramos"}
                                      // eslint-disable-next-line no-mixed-operators
                                      readOnly></input> */}

                                  </li>
                                )
                                :

                                combosPropsVer2[combo.index].content.map((item, indexComboInCombos) =>

                                  <li style={{ marginBottom: "1vh" }}>{item.description} x {item.quantity} {item.unit} - Total : {Number(item.finalPrice !== "" ? item.finalPrice : item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} {" " + item.TypeOfCut}|
                                                    {/* <br /> */}
                                    {/* <b> Peso : </b>
                                    <input type="text" id={"combo" + combo.index + indexComboInCombos} value={item.unit === "UNIDAD" || item.unit === "UNIDADES" ? null : item.weight + " Gramos"}
                                      // eslint-disable-next-line no-mixed-operators
                                      readOnly></input> */}

                                  </li>
                                )
                            }
                          </ul>
                        </li>
                        :
                        <li key={indexComboinCart}>
                          {combo.content.title} x {quantity(combo.quantity)} | <b>{Number(combo.content.price * (quantity(combo.quantity))).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b>
                          <ul>
                            {
                              combo.content.content.map((item, indexComboInCombos) =>

                                <li style={{ marginBottom: "1vh" }}>{item.description} x {item.quantity} {item.unit} - Total : {Number(item.finalPrice !== "" ? item.finalPrice : item.price).toLocaleString("de-AT", { style: "currency", currency: "USD" })} {" " + item.TypeOfCut}|

                                                    {/* <br /> */}
                                  {/* <b> Peso : </b>

                                  <input type="text" id={"combo" + combo.index + indexComboInCombos} value={item.unit === "UNIDAD" || item.unit === "UNIDADES" ? null : item.weight + " Gramos"}
                                    // eslint-disable-next-line no-mixed-operators
                                    readOnly></input> */}

                                </li>
                              )
                            }
                          </ul>
                        </li>
                    )
                }
              </ul>
              <p style={{ fontSize: "13px" }}>Valor domicilio: <b>{
                Cart.delivery_price !== undefined ?
                  Number(Cart.delivery_price).toLocaleString("de-AT", { style: "currency", currency: "USD" }) :
                  "Este campo pertenece a una nueva versión de Santas Carnitas App."}</b></p>
              <p>Total: <b>{Number(total).toLocaleString("de-AT", { style: "currency", currency: "USD" })}</b></p>
              <small className="Form-footer-cart-body-info">Los valores de esta cotización son aproximados, pueden variar por su peso exacto al momento de la liquidación.</small>
            </div>
          </section>
        </footer>

      </section>


      {/* <section className="Form-Loading">
        <img src={Logo} alt="Santas Carnitas" />
        <p>{loading_status}</p>
      </section> */}
    </div >
  )
}
